import React from "react"

export default function LogoHeading() {

  return (
    <>
      <span className="start">M<div className="a-adjust ">a</div>ri<div className="a-adjust ">a</div>nne</span>
      <span className="end">M<div className="a-adjust ">a</div>cR<div className="a-adjust ">a</div>e</span>
    </>     
  )
}
